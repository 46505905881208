const setPerDiem = function() {
  let checked = $('.per_diem_checkbox').is(':checked')

  $('#per_diem_row').toggleClass('d-none', !checked)
  $('#receipt_row').toggleClass('d-none', checked)

  if (checked) {
    $('.expense_vendor, .expense_description, .expense_amount').attr('readonly', 'readonly')

    if (parseInt($('.per_diem_number_of_people_field').val()) > 1)
      $('#per_diem_names_div').removeClass('d-none')

    if ($('.per_diem_number_of_people_field').val() == '' || $('.per_diem_number_of_days_field').val() == '') {
      $('#expense_expense_at_text').val($('#starting_at_trip_leg').val())
      $('.per_diem_number_of_days_field').val($('#days_in_trip_leg').val())
      $('.per_diem_number_of_people_field').val(1)
    }

    setPerDiemFields()
  } else {
    $('.expense_vendor, .expense_description, .expense_amount').removeAttr('readonly')
    $('.per_diem_number_of_days_field, .per_diem_number_of_people_field, .per_diem_names_field').val('')
    $('.per_diem_international_field').prop('checked', false)
  }
}

const setPerDiemFields = function() {
  if (!$('#expense_per_diem').is(':checked')) return

  let type = $('input[name="expense[expense_type]"]').val()
  if (type == 'meal') {
    setPerDiemMealFields()
  } else if (type == 'lodging') {
    setPerDiemLodgingFields()
  }
}

const setPerDiemMealFields = function() {
  let days = parseInt($('.per_diem_number_of_days_field').val())
  let people = parseInt($('.per_diem_number_of_people_field').val())
  if (isNaN(days) || isNaN(people)) return

  let rate = $('#per_diem_local_rate').val()
  if ($('.per_diem_international_field').is(':checked'))
    rate = $('#per_diem_international_rate').val()

  $('.expense_vendor').val('Per-diem meals')
  if ($('.per_diem_number_of_people_field').val() <= 1)
    $('.expense_description').val('Per-diem meals')
  $('.expense_amount').val($.number(parseFloat(rate) * days * people, 2))
}

const setPerDiemLodgingFields = function() {
  $('input[name="expense[vendor]"], input[name="expense[description]"]').val('Per-diem lodging')

  let days = parseInt($('input[name="expense[lodging_nights]"]').val())
  let rate = parseFloat($('input[name="expense[per_diem_lodging_rate]"]').val())
  if (isNaN(days) || isNaN(rate)) return

  $('.expense_amount').val($.number(rate * days, 2))
}

$(document).on('click', '.per_diem_checkbox', setPerDiem)

$(document).on('change', '.per_diem_number_of_people_field', function() {
  if ($(this).val() > 1) {
    $('#per_diem_names_div').removeClass('d-none')
  } else {
    $('.per_diem_names_field').val('')
    $('#per_diem_names_div').addClass('d-none')
  }
})

$(document).on('change keyup', '.per_diem_number_of_people_field, .per_diem_number_of_days_field, input[name="expense[lodging_nights]"], input[name="expense[per_diem_lodging_rate]"]', setPerDiemFields)
$(document).on('click', '.per_diem_international_field', setPerDiemFields)

$(document).on('change', '.per_diem_names_field', function() {
  $('.expense_description').val($(this).val())
})

$(function() {
  if ($('input[name="expense[per_diem]"]').is(':checked')) setPerDiem()
})
