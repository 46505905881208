import Highcharts from 'highcharts'

const dataLabels = function(style) {
  return {
    enabled: true,
    style: style,
    formatter: function() {
      if (this.y > 0) return '$' + this.y.toFixed(2)
    }
  }
}

$(function () {
  let el = document.getElementById('chartDiv')
  if (!el) return

  let data = JSON.parse(el.getAttribute('data-series'))

  let currentData = data.map(function(obj) {
    if (!obj.current) return { y: 0, name: '' }
    return { y: parseFloat(obj.value), name: '$' + obj.value.toString() }
  })

  let otherData = data.map(function(obj) {
    if (obj.current) return { y: 0, name: '' }
    return { y: parseFloat(obj.value), name: '$' + obj.value.toString() }
  })

  Highcharts.chart(el, {
    chart: {
      type: 'column',
      animation: false,
      margin: [ 50, 50, 100, 80],
      backgroundColor: '#f8f9fa',
      borderColor: '#dee2e6',
      borderWidth: 2,
    },
    credits: { enabled: false },
    title: {
      text: 'Vendor History by Invoice'
    },
    xAxis: {
      categories: JSON.parse(el.getAttribute('data-categories')),
      labels: {
        rotation: -45,
        align: 'right'
      }
    },
    yAxis: {
      min: 0,
      title: { text: 'Total Amount' }
    },
    legend: { enabled: false },
    tooltip: {
      formatter: function() { return '$' + this.y.toFixed(2) + '<br>' + this.x }
    },
    colors: ['#007bff', '#28a745', '#dc3545', '#ffc107', '#343a40'],
    series: [
      {
        name: 'Other',
        data: otherData,
        dataLabels: dataLabels({ fontWeight: 'normal', color: '#6c757d' })
      }, {
        name: 'Current',
        data: currentData,
        dataLabels: dataLabels({ fontWeight: 'bold', color: '#28a745' })
      }
    ]
  })
})
