$(document).on('click', '.noteBlock_more', function() {
  let el = $(this).parents('.noteBlock')
  el.find('.noteBlock_minimized').hide()
  el.find('.noteBlock_full').show()
})

$(document).on('click', '.noteBlock_less', function() {
  let el = $(this).parents('.noteBlock')
  el.find('.noteBlock_full').hide()
  el.find('.noteBlock_minimized').show()
})
