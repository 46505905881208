const setMileageAmount = function() {
  if ($('.mileage-field').length == 0 || $('#expense_expense_at_text').length == 0) return

  let dateText = $('#expense_expense_at_text').val()
  let miles = $('.mileage-field').val()
  if (miles == '' && dateText == '') return

  if ($('#mileage_is_business_office').val() == 'false') {
    $('.expense_vendor').val($('#mileage_vendor').val())
    $('.expense_description').val($('#mileage_description').val())
    $('.expense_vendor, .expense_description, .expense_amount').attr('readonly', 'readonly')
  }

  $.get(`/expenses/mileage_amount?date_text=${dateText}&miles=${miles}`, function(data) {
    $('.expense_amount').val(data)
  })
}

$(document).on('change keyup', '.mileage-field', setMileageAmount)
$(document).on('change.datetimepicker', '[id^=datepicker-expense_at_text]', setMileageAmount)

$(function() {
  if ($('meta[name="setMileage"]').length > 0) setMileageAmount()
})
