$(document).on('change', '.invoiceAmountField', function() {
  let total = 0.0
  let error = false

  $('.invoiceAmountField').each(function() {
    if (isNaN($(this).val())) {
      $('.invoiceTotalAmount').html('ERROR')
      error = true
      return false
    }
    total = total + parseFloat($(this).val())
  })

  if (!error)
    $('.invoiceTotalAmount').number(total, 2)
})

$(document).on('cocoon:after-insert', function(e, inserted) {
  setTimeout(() => { $(inserted).find('select[name$="[category_id]"]').trigger('chosen:open.chosen') }, 100)
})

// Auto-format number when searching categories
const categorySearch = 'select[name$="[category_id]"] + .chosen-container input.chosen-search-input'

// Suppress dash on numerical search since we're adding that
$(document).on('keypress', categorySearch, function(e) {
  if (e.key == '-' && /^[0-9-]+$/.test(this.value))
    e.preventDefault()
})

// Add dashes for format 000-0000-00-0
$(document).on('keyup', categorySearch, function(e) {
  if (e.key != 'Backspace' && /^\d{3}(|-\d{4}(|-\d{2}))$/.test(this.value))
    this.value += '-'
})
