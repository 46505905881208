import ujs from '@rails/ujs'

$(function() {
  let form = document.querySelector('form.search-form')
  if (form) {
    ujs.fire(form, 'submit')
    $(form).find('input[name="page"]').remove() // Remove saved page number so it doesn't pollute subsequent searches
  }
})

$(document).on('click', '#clearSearch', function(e) {
  e.preventDefault()

  let form = $(this).parents('form')
  form.find('input[type="text"], select').val('')
  form.find('.chzn-select-deselectable').trigger('chosen:updated')
  ujs.fire(form[0], 'submit')
})
