import './charts'
import '../css'

import '@nathanvda/cocoon'

const setSectionVisibility = function() {
  let visible = $('.hide-section:not(.d-none)').map((i, el) => el.getAttribute('data-id')).get()

  $.ajax({
    url: location.href,
    method: 'POST',
    data: { sections: visible },
    dataType: 'script'
  })
}

$(document).on('cocoon:after-insert', '.cocoon-form', function() {
  $(this).find('.chzn-select').chosen({ width: '100%' })
})

$(document).on('click', '.hide-section', function(e) {
  e.preventDefault()

  let id = $(this).attr('data-id')
  $(`#hide-section-${id}, .hideable-section-${id}`).addClass('d-none')
  $(`#show-section-${id}, #hidden-message-${id}`).removeClass('d-none')
  setSectionVisibility()
})

$(document).on('click', '.show-section', function(e) {
  e.preventDefault()

  let id = $(this).attr('data-id')
  $(`#hide-section-${id}, .hideable-section-${id}`).removeClass('d-none')
  $(`#show-section-${id}, #hidden-message-${id}`).addClass('d-none')
  setSectionVisibility()
})

$(function() {
  $('.chzn-select').chosen({ width: '100%' })
  $('.chzn-select-category').chosen({ width: '180px' })
  $('.chzn-select-category-business-office').chosen({ width: '240px' })
  $('.chzn-select-deselectable').chosen({ allow_single_deselect: true, width: '100%' })

  $('.datepicker-component').datetimepicker({ format: 'L' })

  if ($('.hide-section:not(.d-none)').length == 0) {
    $('.show-section').first().trigger('click')

    if ($('.show-section').first().attr('data-id') == 'unsubmitted') {
      $('.show-section:not(.d-none)').first().trigger('click')
    }
  }

  $('[data-toggle="tooltip"]').tooltip()
})
