import ujs from '@rails/ujs'
ujs.start()

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import './globals/jquery'
import './globals/moment'

import 'bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'

import 'chosen-js'
import 'chosen-js/chosen.css'

import 'tempusdominus-bootstrap-4'
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css'

import 'jquery.cookie'
import 'jquery-number'

import '#/application/js'
import '#/business_office/js'
import '#/expenses/js'
import '#/invoices/js'
import '#/reports/js'
