$(document).on('click', '#reports_details_more', function() {
  $('#reports_details_content').show()
  $('#reports_details_more').hide()
})

$(document).on('click', '#reports_details_less', function() {
  $('#reports_details_more').show()
  $('#reports_details_content').hide()
})

$(document).on('click', '#invoices_details_more', function() {
  $('#invoices_details_content').show()
  $('#invoices_details_more').hide()
})

$(document).on('click', '#invoices_details_less', function() {
  $('#invoices_details_more').show()
  $('#invoices_details_content').hide()
})

$(document).on('change', '#selectRecurringInvoicesCheckbox', function() {
  $('.processCheckbox').prop('checked', $(this).is(':checked'))
})

$(document).on('click', '.toggle-export-pending', function(e) {
  e.preventDefault()

  let button = $(this)
  let type = $(this).attr('data-type')
  let params = {
    report_ids: $(`.report_${type}:checked`).map((i, el) => el.value).get(),
    invoice_ids: $(`.invoice_${type}:checked`).map((i, el) => el.value).get()
  }

  button.addClass('disabled').prop('disabled', true)

  $.get(`/business_office/toggle_export_pending?${$.param(params)}`, function(data) {
    if (data == '') {
      $('input[type="checkbox"]').prop('checked', false)
      window.location.reload()
    } else {
      alert(data)
      button.removeClass('disabled').prop('disabled', false)
    }
  })
})

$(document).on('paste', 'form#check-numbers input[type="text"]', function(e) {
  let numbers = e.originalEvent.clipboardData.getData('text').trim().split('\n')
  if (numbers.length > 1) {
    e.preventDefault()

    // If multiple check numbers pasted, fill in each field starting where pasted
    let inputs = $('input[type="text"]')
    let start = inputs.index(this)
    inputs.slice(start, start + numbers.length).each((i, el) => { el.value = numbers[i] })
  }
})

$(document).on('submit', 'form#taxable-income', function(e) {
  if ($('#start_at').val() == '' || $('#end_at').val() == '') {
    e.preventDefault()
    alert('Please enter both a starting and ending date.')
  }
})
