$(document).on('click', '#return_same_day', function() {
  $('#returned_at_div').toggleClass('d-none', $(this).is(':checked'))
})

$(document).on('change', '#proxySelect', function() {
  window.location = `/vendors/${$(this).val()}/reports`
})

$(document).on('click', '.trip-modal', function() {
  $('#trip-modal-div').load($(this).attr('data-url'), function() {
    $('#tripModal').modal({ show: true })
    $('.datepicker-component').datetimepicker({ format: 'L' })
  })
})

$(document).on('click', '.trip-leg-modal', function() {
  $('#trip-leg-modal-div').load($(this).attr('data-url'), function() {
    $('#tripLegModal').modal({ show: true })
    $('.datepicker-component').datetimepicker({ format: 'L' })
  })
})

$(function () {
  if ($('meta[name="startCodingReview"]').length > 0)
    $('.expense_document_window_link').first().trigger('click')
})
