$(document).on('change', '.coding-review-filter', function() {
  let checked = $(this).is(':checked')
  $($(this).attr('data-filter')).toggle(checked)
  $.cookie($(this).attr('data-cookie'), checked.toString(), 1)

  if (!checked) {
    $($(this).attr('data-linked')).prop('checked', false)
  }

  if (this.id == 'coding_review-CreditCardCheckboxFilter') {
    $('.credit-card-subfilter').toggle(checked)
    $('.credit-card-subfilter input').prop('checked', checked).trigger('change')
  }
})

$(document).on('change', '.coding-review-select', function() {
  $($(this).attr('data-linked')).prop('checked', $(this).is(':checked'))
})

$(document).on('change', '#admin_review-select-all', function() {
  $('.hideable-section-admin_review table input[type="checkbox"][name="invoices[]"]').prop('checked', $(this).prop('checked'))
})

$(document).on('change', '.hideable-section-admin_review table input[type="checkbox"][name="invoices[]"]', function() {
  if (!$(this).prop('checked')) {
    $('#admin_review-select-all').prop('checked', false)
  }
})

$(function() {
  if ($('meta[name="businessOfficeInvoices"]').length == 0) return

  if ($.cookie('filterGeneral') == 'false')
    $('#coding_review-GeneralCheckboxFilter, #admin_review-GeneralCheckboxFilter').prop('checked', false).trigger('change')

  if ($.cookie('filterCreditCard') == 'false') {
    $('#coding_review-CreditCardCheckboxFilter, #admin_review-CreditCardCheckboxFilter').prop('checked', false).trigger('change')
  } else {
    if ($.cookie('filterAmex') == 'false')
      $('#coding_review-AmexCheckboxFilter').prop('checked', false).trigger('change')

    if ($.cookie('filterVisa') == 'false')
      $('#coding_review-VisaCheckboxFilter').prop('checked', false).trigger('change')
  }

  if ($.cookie('filterDirectDeposit') == 'false')
    $('#coding_review-DirectDepositCheckboxFilter, #admin_review-DirectDepositCheckboxFilter').prop('checked', false).trigger('change')

  if ($.cookie('filterRecurringInvoice') == 'false')
    $('#coding_review-RecurringInvoiceCheckboxFilter, #admin_review-RecurringInvoiceCheckboxFilter').prop('checked', false).trigger('change')
})
