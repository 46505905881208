import './mileage'
import './per-diem'

const loadCategories = function() {
  let department = $('.department-select').val()
  if (!department || department == '') return

  let category = parseInt($('.category-id-hidden').val())
  let el = $('.category-select')

  $.get(`/expenses/categories?department_id=${department}&expense_type=${$('.expense-type').val()}`, function(data) {
    el.find('option').remove()
    el.append(data)
    el.chosen()

    if (category > 0) el.val(category)
    el.trigger('chosen:updated')
  })
}

$(document).on('click', '.for_report_review_document_link', function(e) {
  e.preventDefault()

  let receiptId = $(this).attr('data-id')
  $.get(`/business_office/receipts/${receiptId}/reviewed`, function(data) {
    $(`#reviewed_message_${receiptId}`).html(data)
  })

  window.open(this.href, 'ExpensesDocumentWindow', 'status=0')
})

$(document).on('click', '.for_invoice_review_document_link', function(e) {
  e.preventDefault()

  let invoiceId = $('#invoice_id').val()
  $.post(`/business_office/invoices/${invoiceId}/reviewed`, { _method: 'patch' }, function(data) {
    $(`#reviewed_${invoiceId}`).html(data)
  })

  $('.finishButton').removeAttr('disabled')
  window.open(this.href, 'ExpensesDocumentWindow', 'status=0')
})

$(document).on('click', '.expense_document_window_link', function(e) {
  e.preventDefault()
  window.open(this.href, 'ExpensesDocumentWindow', 'status=0')
})

$(document).on('click', '#explanation-link', function(e) {
  e.preventDefault()

  let add = $('#div-explanation').is(':visible')
  $('#explanation-link').text((add ? 'Add' : 'Remove') + ' explanation')
  $('#div-explanation textarea').val('')
  $('#div-explanation').toggleClass('d-none', add)
})

$(document).on('click', '.mailing-receipt-checkbox', function() {
  $('#mailing-receipts-explination-div').toggleClass('d-none', !$(this).is(':checked'))
  if ($(this).is(':checked'))
    $('.receipt-delete-button').trigger('click') // TODO: double check this works vs `$(this).click()` on each
})

$(document).on('change', '.department-select', loadCategories)

$(loadCategories)
